<template>
    <v-container fluid>
        <v-card
            width="1000"
            class="mx-auto pa-4"
            elevation="2"
            outlined
        >
            <v-card-title>
                Teachers
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details=""
                    clearable
                    class="mr-12"                    
                ></v-text-field>                
                <v-btn
                    color="primary ml-6 pt-2"                    
                    text
                    outlined
                    class="mb-2"
                    @click="dialogEdit = true" 
                    v-if="!disable"                  
                >
                    Add New Teacher
                </v-btn>
            </v-card-title>

            <v-tabs
                v-model="tab"
                color="primary"
            >
                <v-tab>
                    Current Teachers
                </v-tab>

                <v-tab>Archive</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card 
                        flat                        
                    >                    
                        <v-data-table
                            class="mt-10"
                            :headers="headers"
                            :items="employees"
                            :search="search"
                            fixed-header
                            height="55vh"
                            :loading="loadingEmployees"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    @click="editEmployee(item)"
                                    x-small
                                    text
                                    outlined
                                    class="mr-2"
                                    v-if="!disable"
                                >
                                    <span
                                        class="mr-2"
                                    >Edit</span>
                                    <v-icon
                                        x-small
                                        class="mr-2 "
                                        
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="editLesson(item)"
                                    x-small
                                    text
                                    outlined
                                    class="mr-2"
                                >
                                    <span
                                        class="mr-2"
                                    >Lessons</span>
                                    <v-icon
                                        x-small
                                        class="mr-2 "
                                        
                                    >
                                        mdi-bookshelf
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    @click="displayResetPassword(item)"
                                    x-small
                                    text
                                    outlined
                                    v-if="!disable"
                                >
                                    <span
                                        class="mr-2"
                                    >Reset</span>
                                    <v-icon
                                        x-small
                                        class="mr-2"
                                        
                                    >
                                        mdi-lock-reset
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card 
                        flat
                    >                    
                        <v-data-table
                            class="mt-10"
                            :headers="headersArchive"
                            :items="employeesArchive"
                            :search="search"
                            fixed-header
                            height="55vh"
                            :loading="loadingEmployees"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    @click="editEmployeeArchive(item)"
                                    x-small
                                    text
                                    outlined
                                    class="mr-2"
                                    v-if="!disable"
                                >
                                    <span
                                        class="mr-2"
                                    >Edit</span>
                                    <v-icon
                                        x-small
                                        class="mr-2 "
                                        
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>                                
                               
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
                
            </v-tabs-items>
           

            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                persistent
            >
                <v-card class="pa-2">
                    <v-card-title>
                        {{ formTitle }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Teacher No"
                                    v-model="editedEmployee.teacher_num"                                   
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="First Name"
                                    v-model="editedEmployee.first_name"                                   
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Last Name"
                                    v-model="editedEmployee.last_name"                                    
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-menu               
                                    ref="menuBirthDate"
                                    v-model="menuBirthDate"
                                    :close-on-content-click="false"                           
                                    transition="scale-transistion"
                                    offset-y
                                    min-width="290px"                            
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="editedEmployee.date_of_birth"
                                            label="Date of Birth"                                            
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :max="new Date().toISOString().substr(0, 10)"
                                            autocomplete="false"                                                    
                                        >
                                            
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="pickerDateOfBirth"
                                        v-model="editedEmployee.date_of_birth"
                                        @change="saveDateOfBirth"
                                    >                                
                                    </v-date-picker>                            
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    label="Post"
                                    v-model="editedEmployee.post_id"
                                    :items="employeePosts"
                                    item-text="rank"
                                    item-value="id"                                    
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4">
                        <span>
                            <span>{{ saveProgress }}</span>
                            <v-progress-circular
                                indeterminate
                                size="16"
                                width="3"
                                v-show="saving"
                                class="ml-2"
                            ></v-progress-circular>
                            <v-icon
                                small
                                color="green" 
                                v-show="saved"
                                class="mx-2"
                            >
                                mdi-check-all
                            </v-icon>
                            <v-icon
                                small
                                color="red"
                                v-show="saveError"
                                class="mx-2"
                            >
                                mdi-alert-circle
                            </v-icon>
                        </span>
                        <v-spacer></v-spacer>                        
                        <v-btn
                            color="blue darken-4"
                            text
                            @click="closeEdit"
                            outlined
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="blue darken-4"
                            text                            
                            outlined
                            @click="saveEmployee"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-dialog
                v-model="dialogLessons"
                max-width="600px"
                persistent
            >
                <v-card>
                    <v-card-title>
                        {{ teacherSelected }}
                    </v-card-title>                   
                    <v-card-text>                        
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    v-model="formClassRecord.class_id"
                                    :items="formClasses"
                                    outlined
                                    dense
                                    label="Form Class"
                                    @change="updateFormClass"
                                    chips
                                    deletable-chips
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-data-table
                                :headers="lessonHeaders"
                                :items="lessons"
                                fixed-header
                                :loading="loadingLessons"
                                height="40vh"
                                :calculate-widths="true"
                                ref="LessonTable"
                                class="lesson-table"
                                :disable-pagination="true"
                                :options="options"
                            >
                                <template v-slot:item="props">
                                    <LessonRow
                                        v-bind:lesson="props.item"
                                        v-bind:lessonClasses="formClasses"
                                        v-bind:lessonSubjects="subjects"
                                    />
                                </template>
                            </v-data-table>
                        </v-row>    
                    </v-card-text>
                    <v-card-actions>
                        <span>
                            <span>{{ saveProgress }}</span>
                            <v-progress-circular
                                indeterminate
                                size="16"
                                width="3"
                                v-show="saving"
                                class="ml-2"
                            ></v-progress-circular>
                            <v-icon
                                small
                                color="green" 
                                v-show="saved"
                                class="mx-2"
                            >
                                mdi-check-all
                            </v-icon>
                            <v-icon
                                small
                                color="red"
                                v-show="saveError"
                                class="mx-2"
                            >
                                mdi-alert-circle
                            </v-icon>
                        </span>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-4"
                            text
                            @click="addLesson"
                            outlined
                        >
                            Add Lesson
                        </v-btn>
                        <v-btn
                            color="blue darken-4"
                            text
                            @click="closeEditLesson"
                            outlined
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                    <v-overlay
                        absolute
                        :value="lessonOverlay"
                        color="secondary"
                        opacity="0.8"
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                            color="primary"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-dialog>

            <v-dialog
                v-model="dialogResetPassword" 
                max-width="600px"
                persistent
            >
                <v-card>
                    <v-card-title 
                        class="subtitle-1"
                        v-text="dialogResetTitle"
                    >                                
                    </v-card-title>
                    <v-card-text
                        v-if="resetProgress"
                    >
                        <v-progress-linear
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="primary" 
                            text
                            outlined
                            @click="closeResetPassword"
                            small                            
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            @click="resetPasswordConfirm"
                            class="ml-6"
                            small                            
                        >
                            Ok
                        </v-btn>                        
                    </v-card-actions>                                     
                </v-card>
            </v-dialog>

        </v-card>
        <v-snackbar
            v-model="snackbar"
            color="primary"
        >
            {{ snackbarText }}
        </v-snackbar>
    </v-container>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LessonRow from './TeacherLessonsRecord';
export default {
    components: {
        LessonRow,
    },
    data: () => ({
        headers: [
            {text: 'Teacher #', align: 'start', sortable: 'false', value: 'teacher_num', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '150'},
            {text: 'First Name', value: 'first_name', width: '100'},
            {text: 'Date of Birth', value: 'date_of_birth', width: '150'},
            {text: 'User Name', value: 'user_name', width: '120'},
            {text: 'Actions', value: 'actions', sortable: 'false', }
        ],
        headersArchive: [
            {text: 'Teacher #', align: 'start', sortable: 'false', value: 'teacher_num', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '150'},
            {text: 'First Name', value: 'first_name', width: '100'},
            {text: 'Date of Birth', value: 'date_of_birth', width: '150'},
            {text: 'User Name', value: 'user_name', width: '120'},
            {text: 'Actions', value: 'actions', sortable: 'false', }
        ],
        lessonHeaders: [
            {text: 'Subj ID', value: 'subjectId', width: '40', sortable: 'false'},
            {text: 'Subject', value: 'subject', width: '200', sortable: 'false'},
            {text: 'Class', value: 'formClass', width: '90', sortable: 'false'},
            {text: '', value: 'actions', sortable: 'false', width: '30'},
            {text: '', value: 'status', sortable: 'false', width: '30'},
        ],
        employees: [],
        employeesArchive: [],        
        defaultLesson: {
            id: '',
            subjectId: '',
            formClass: '',
            employeeId: ''
        },
        blankLesson: {},
        editedIndex: -1,
        search: '',
        dialogLessons: false,
        defaultEmployee: {
            id: '',
            first_name: '',
            last_name: '',
            teacher_num: '',
            date_of_birth: '',
            user_name: '',
            post_id: '',
        },        
        editedEmployee: {},
        selectFormClass: '',
        selectEditClass: '',
        formClasses: [],
        editLessonClasses: [],
        editLessonClass: '',
        editLessonSubject: {id: '', title: ''},
        loadingEmployees: true,       
        loadingLessons: true,
        subjects: [],
        lessons: [],
        defaultFormClassRecord: {
            id: '', 
            employee_id: '',
            class_id: '',
            academic_year_id: '', 
        },
        formClassRecord: {},
        saving: false,
        saved: false,
        saveError: false,
        saveProgress: '',
        options: {
            itemsPerPage: -1,
        },
        dialogResetPassword: false,
        resetPassword: false,
        cancelButton: false,
        okCloseButton: false,
        resetProgress: false,
        dialogResetTitle: '',
        okButton: false,
        snackbar: false,
        snackbarText: '',
        dialogEdit: false,
        menuBirthDate: false,
        dialogNewEmployee: false,
        employeePosts: [],
        tab: null,
        disable: false,
    }),
    created () {
        this.initialize();
    },
    watch: {
        getLessons: {
            handler(val){
                this.lessons = [...val];
            }
        },

        menuBirthDate (val) {
            val && setTimeout(() => (this.$refs.pickerDateOfBirth.activePicker = 'YEAR'))
        }
    },
    computed: {
        ...mapGetters({
            lessonOverlay: 'admin/getLessonOverlay',
            getLessons: 'admin/getTeacherLessons',
            getSelectedEmployeeId: 'admin/getSelectedEmployeeId',
            getAcademicYearId: 'admin/getAcademicYearId',
            employeeSelected: 'admin/getEmployeeSelected',
            userName: 'auth/getUserName',              
        }),

        teacherSelected(){
            return this.editedEmployee.first_name + ' ' + this.editedEmployee.last_name;
        },
        
        formTitle () {
            return this.editedIndex === -1 ? 'New Teacher' : 'Edit Teacher';
        }
    },
    methods: {
        ...mapActions({
            getEmployees: 'admin/getEmployees',
            getFormClasses: 'admin/getFormClasses',
            getTeacherLessons: 'admin/getTeacherLessons',
            postTeacherLesson: 'admin/postTeacherLesson',
            getSubjects: 'admin/getSubjects',
            getFormTeacherClass: 'admin/getFormTeacherClass',
            postFormTeacherAssignment: 'admin/postFormTeacherAssignment',
            resetPasswordEmployee: 'admin/resetPasswordEmployee',
            updateEmployee: 'admin/updateEmployee',
            addEmployee: 'admin/addEmployee',
            getEmployeePosts: 'admin/getEmployeePosts',            
        }),

        ...mapMutations({
            setEditedLesson : 'admin/setEditedLesson',
            setLessonOverlay: 'admin/setLessonOverlay',
            setTeacherLessons: 'admin/setTeacherLessons',
            setSelectedEmployeeId: 'admin/setSelectedEmployeeId',
            setFormTeacherAssignment: 'admin/setFormTeacherAssignment',
            setEmployeeSelected: 'admin/setEmployeeSelected',
        }),

        async initialize () {
            console.log(this.userName);
            if(this.userName == 'Deans/HODs') this.disable = true;
            this.editedEmployee = Object.assign({}, this.defaultEmployee);
            try {
                const promiseEmployees = this.getEmployees();
                const promiseFormClasses = this.getFormClasses();
                const promiseEmployeePosts = this.getEmployeePosts();

                const [
                    { data: dataEmployees },
                    { data: dataFormClasses },
                    { data: dataEmployeePosts }
                ] = await Promise.all([
                    promiseEmployees,
                    promiseFormClasses,
                    promiseEmployeePosts
                ])
                // console.log(dataEmployees);
                // this.employees = dataEmployees;
                
                this.mapEmployees(dataEmployees);
                this.loadingEmployees = false;
                dataFormClasses.forEach(record => {
                    this.formClasses.push(record.id);
                    this.editLessonClasses.push(record.id);
                });

                this.employeePosts = dataEmployeePosts;

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        mapEmployees (data) {
            this.employees = [];
            this.employeesArchive = [];
            data.forEach(employee => {
                if(employee.deleted_at) this.employeesArchive.push(employee);
                else this.employees.push(employee);
            })
        },

        async editLesson(item)
        {
            this.saved = false;
            this.saving = false;
            this.saveError = false;
            this.saveProgress = '';            
            this.blankLesson = {...this.defaultLesson};                        
            this.blankLesson.employeeId = item.id;
            this.formClassRecord.class_id = '';
            
            this.setSelectedEmployeeId(item.id);           
            this.dialogLessons = true;
            this.setLessonOverlay(true);
            this.editedEmployee = Object.assign({}, item);
            try {
                let response = await this.getSubjects();                
                let records = response.data;
                records.forEach(record => {
                    let subject = {
                        id: record.id,
                        title: record.title
                    }
                    this.subjects.push(subject);
                })

                response = await this.getTeacherLessons(item.id);                
                records = response.data;
                let teacherLessons = [];
                records.forEach(record => {                     
                    let lesson = {
                        id: record.id,
                        subjectId: record.subject_id,
                        subject: record.subject.title,
                        formClass: record.form_class_id,
                        employeeId: item.id
                    }
                    teacherLessons.push(lesson);
                })
                this.setTeacherLessons(teacherLessons);
                
                response = await this.getFormTeacherClass();
                //console.log(response);
                if(response.data.length > 0){
                    this.formClassRecord = {...response.data[0]};
                }
                else{
                    this.formClassRecord = {...this.defaultFormClassRecord};
                }
                //console.log(this.formClassRecord);

                this.loadingLessons = false;
                this.setLessonOverlay(false);
                
            } catch (error) {
                console.log(error);
            }
        },      

        closeEditLesson(){            
            this.lessons = [];
            this.dialogLessons = false;
        },
              
        addLesson(){
            console.log('add lesson ..');
            this.lessons.push(this.blankLesson);
            let currentRows = this.lessons.length;
            console.log(currentRows);
            //console.log(this.$refs)
            //this.$refs['LessonTable'].$el.scrollIntoView(true);
            let div = document.querySelector('.lesson-table > div');
            let scrollHeight = div.scrollHeight;
            console.log(scrollHeight);
            console.log(div.clientHeight);
            div.scrollTop = scrollHeight - div.clientHeight;            
        },

        async updateFormClass(){
            console.log('updating form class...'); 
            this.saved = false;
            this.saveError = false;
            this.saveProgress = 'Saving ';
            this.saving = true;           
            
            let formClassAssignment = {
                id: this.formClassRecord.id,
                class_id: this.formClassRecord.class_id,
                employee_id: this.getSelectedEmployeeId,
                academic_year_id: this.getAcademicYearId,
            }
            this.setFormTeacherAssignment(formClassAssignment);

            try {
                let response = await this.postFormTeacherAssignment();
                console.log(response);
                // this.formClassRecord.id = response.data.id;
                this.saving = false;
                this.saveError = false;
                this.saveProgress = 'Saved ';
                this.saved = true;
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saving = false;
                this.saved = false;
                this.saveProgress = 'Error Occured';
                this.saveError = true;
            }
        },

        async displayResetPassword (item) {
            //console.log(item);
            this.okCloseButton = true;
            this.cancelButton = true;
            this.dialogResetTitle = `Reset ${item.first_name} ${item.last_name} password ?`;
            this.dialogResetPassword = true;
            this.setEmployeeSelected(item);
            
        },

        closeResetPassword () {
            this.dialogResetPassword = false;
        },

        async resetPasswordConfirm () {
            this.dialogResetTitle = `Resetting ${this.employeeSelected.first_name} ${this.employeeSelected.last_name} password..`
            this.resetProgress = true;
            try {
                let response = await this.resetPasswordEmployee();
                this.resetProgress = false;
                this.snackbarText = `${this.employeeSelected.first_name} ${this.employeeSelected.last_name} password reset.`
                console.log(response.status);
                this.$nextTick(() => {
                    this.snackbar = true;
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.resetProgress = false;
                this.snackbarText = 'Error occured.';
                this.$nextTick(() => {
                    this.snackbar = true;
                })
                
            }
            this.dialogResetPassword = false;
        },

        editEmployee (item) {
            this.editedIndex = this.employees.indexOf(item);            
            this.editedEmployee = Object.assign({}, item);
            this.dialogEdit = true;
        },

        editEmployeeArchive (item) {
            this.editedIndex = this.employeesArchive.indexOf(item);
            this.editedEmployee = Object.assign({}, item);
            this.dialogEdit = true;
        },

        saveDateOfBirth (date) {
            this.$refs.menuBirthDate.save(date);                    
        },

        closeEdit () {
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.editedEmployee = Object.assign({}, this.defaultEmployee);
                this.editedIndex = -1;
                this.setSaveStatus('closed');
            })
        },

        async saveEmployee () {
            let response = null;
            this.setSaveStatus();           
            this.setEmployeeSelected(this.editedEmployee);
            // console.log(this.editedEmployee);
            console.log(this.editedIndex);           

            try {
                response = this.editedIndex === -1 ? await this.addEmployee() : await this.updateEmployee();
                console.log(response);

                response = await this.getEmployees();                             
                //this.employees = response.data;
                this.mapEmployees(response.data);
                this.setSaveStatus('saved');
                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus('error');
            }
        },

        setSaveStatus (status = 'saving'){
            this.saving = false;
            this.saved = false;
            this.saveError = false;
            this.saveProgress = '';
            switch (status) {
                case 'saving':
                    this.saving = true;
                    this.saveProgress = status;
                    break;
                case 'saved':
                    this.saved = true;
                    this.saveProgress = status;
                    break;
                case 'error':
                    this.saveError = true;
                    this.saveProgress = status;
                    break;
                case 'closed':
                    break;
            }
        }
        
    }
}
</script>