<template>
    <tr @click="recordSelected">
        <td>{{ markRecord.count }}</td>
        <td class="pt-1">
            {{ markRecord.name }}
        </td>        
        <td >
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                :filled="disableCourseMark"
                :disabled="disableCourseMark"
                hide-details
                @blur="recordChanged"                
            ></v-text-field>
        </td>        
        <td>
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                hide-details
                @blur="recordChanged"
                :filled="disableExamMark"
                :disabled="disableExamMark"
            ></v-text-field>
        </td>
        
        <td>
           <v-select
                v-model="codedComment"                
                :items="comments1"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template> 

            </v-select>
        </td>            
        <td>
            <v-select
                v-model="codedComment1"                
                :items="comments2"
                item-value="id"
                outlined
                dense
                hide-details
                return-object                
                @change="recordChanged"                
            >
                <template v-slot:selection="{item}">
                    {{ item.id }}
                </template>

                <template
                    v-slot:item=data
                > 
                    {{ data.item.id}} {{ data.item.comment }}
                </template>
            </v-select>
        </td>
        <td>
            <v-tooltip 
                bottom
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        :color="themeColor"                        
                        v-bind="attrs"
                        v-on="on"
                        v-show="saved"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        v-show="error"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                :color="themeColor"
                v-show="saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,        
    },
    created () {
        this.initialize();
    },  
    watch: {        
        codedComments: {
            deep: true,
            handler(value){
                if(value.length != 0){
                    this.setComments();
                }
            }
        },
        codedComment: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment = value.id;
            }
        },
        codedComment1: {
            handler(value){
                //console.log(value);
                this.markRecord.coded_comment_1 = value.id;
            }
        },
       
    },       
    data() {
        return {
            commentsConduct: [],
            commentsApplication: [],
            commentsPreparedness: [],            
            comments1: [],
            comments2: [],            
            markRecord: {},
            codedComment: { id: null, comment: null },
            codedComment1: { id: null, comment: null },
            conduct: { id: null, comment: null },
            application: { id: null, comment: null },
            preparedness: { id: null, comment: null }, 
            selectColumns:[
                { text: 'Conduct', model: 'conduct' }
            ],
            saved: false,
            saving: false,
            error: false,
            disableConduct: false,
            disableApplication: false,
            disablePreparedness: false,
            disableCodedComment: false,
            disableCodedComment1: false,
            disableExamMark: false,
            disableCourseMark: false,
            termTwo: false,           
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm', 
        }),
        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        },
        total(){
            let formLevel = this.lessonSelected.formLevel;
            
            if(formLevel == 5) return 'NA';
            else if(this.currentTerm == 2){
                if(formLevel == 6) return 'NA'
            }
            else if(this.currentTerm == 3){
                if(formLevel == 3 || formLevel == 4) return 'NA'
            }
            return (this.markRecord.course_mark * 0.6 + this.markRecord.exam_mark * 0.4).toFixed(1);
        }        
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
        }),
        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),              
        async recordChanged(){
            //console.log(this.recordBlankCheck());
            if(!this.recordBlankCheck()){
                console.log('record changed...');            
                this.saving = true;
                this.saved = false;
                this.error = false;
                this.setUpdatedRecord(
                {
                    "student_id" : this.markRecord.student_id,
                    "year" : this.markRecord.year,
                    "term" : this.markRecord.term,
                    "subject_id" : this.markRecord.subject_id,
                    "exam_mark" : this.markRecord.exam_mark,
                    "course_mark" : this.markRecord.course_mark,                   
                    "coded_comment" : this.codedComment.id,
                    "coded_comment_1" : this.codedComment1.id,
                    "employee_id" : this.employeeId,                   
                }
            );
                let response = await this.save();
                //console.log(response);

                if(response && (response.status === 200 || response.status === 201)){
                    this.saving = false;
                    this.saved = true;
                    this.error = false;
                    //console.log(this.marksEntered);
                    //let entered = this.marksEntered;
                    //this.setMarkSheetEntered(++entered);
                }
                else{
                    this.saving = false;
                    this.error = true;
                    this.saved = false;
                }    
            }                  
        },
        recordBlankCheck(){
            let record = [];
            let blank = true;
            //console.log(this.markRecord);
            record.push(this.markRecord.course_mark);
            record.push(this.markRecord.exam_mark);           
            record.push(this.markRecord.test_1);           
            record.push(this.markRecord.test_2);           
            record.push(this.markRecord.project);           
            record.push(this.markRecord.coded_comment);
            record.push(this.markRecord.coded_comment_1);
            //console.log(record);
            //console.log(this.markRecord['coded_comment']);
            record.forEach(element => {
                if(element) blank = false;
            })

            // if(this.markRecord.course_mark || this.markRecord.exam_mark || this.markRecord.coded_comment || this.markRecord.coded_comment_1){
            //     console.log('not blank');
            //     return false
            // }
            // console.log('blank');
            return blank;
        },
        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },
        recordSelected(){
            //console.log('marksheetrecord: ', this.markRecord);
            console.log("record selected");
            let records = this.table2Records;
            //console.log(records);
            let entered = 0;
            records.forEach(record => {
                if(record.course_mark || record.exam_mark || record.conduct || record.application || record.preparedness || record.coded_comment || record.coded_comment_1){
                    entered++;
                }
            })
            console.log(entered);
            //console.log(records);          
            this.setStudentSelected(this.markRecord.name);
        },        
        setComments(){ 
            this.comments1.push({id: null, comment: null});  
            this.comments2.push({id: null, comment: null});
            //console.log(this.codedComments);  
            this.codedComments.forEach(record =>{
                this.comments1.push({ id: parseInt(record.id), comment: record.detail });
                this.comments2.push({ id: parseInt(record.id), comment: record.detail });
            });           
            
            if(this.studentRecord.coded_comment){
                this.codedComment = { id: parseInt(this.studentRecord.coded_comment) };
            } 
            if(this.studentRecord.coded_comment_1){
                this.codedComment1 = { id: parseInt(this.studentRecord.coded_comment_1) };
            }
            
        },
        setDisabledFields(){           
            
            if(this.currentTerm == 1 && this.lessonSelected.formLevel > 4){
                //this.termTwo = true;
                this.disableExamMark = true;            
            }

            if(this.currentTerm == 2 && this.lessonSelected.formLevel != 5){
                this.disableExamMark = true
            }
            
        },
        initialize(){
            this.markRecord = {...this.studentRecord};
            //console.log(this.markRecord);
            this.savedDataCheck();
            this.setComments();
            //console.log('initializing..')
            this.setDisabledFields();
            //if(this.currentTerm == 2) this.termMarkOnly = true;
            //console.log(this.currentTerm);
        }
    }
}
</script>