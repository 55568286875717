<template>
    <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid

            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col sm="8" md="6" lg="4">
                        <v-card class="elevation-12">
                            
                            <v-row no-gutters>
                                <v-col cols="12" md="5" :class="signInClass">
                                
                                <v-img
                                    src="../../assets/logo.png"
                                    max-width="70"
                                    contain
                                    class="d-flex"
                                ></v-img>                                            
                                
                                <v-card-text class="white--text mt-4 mb-4">
                                    <div class="text-h5 text-center font-weight-light mb-3">Welcome to </div>
                                    <div class="text-h4 text-sm-h5 text-center">{{ schoolName }}</div>                                                
                                </v-card-text>
                                    
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-window v-model="step">
                                        <v-window-item :value="1"> 
                                    
                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="getloginErrors"
                                                    class="red--text"
                                                >
                                                    {{ getloginErrors }}
                                                </v-alert>    
                                            </div>

                                            <v-card-text>
                                                <div :class="title_css">Admin Sign in</div>                                            
                                            </v-card-text>

                                            <v-form 
                                                class="pa-5"
                                                @submit.prevent
                                            >
                                                <v-text-field
                                                    label="Username"
                                                    placeholder="Enter username"
                                                    name="id"                                                
                                                    type="text"
                                                    :color="icon_color"
                                                    v-model="formData.name"
                                                    clearable
                                                    autofocus
                                                    v-on:keyup="nextStep"
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        :color="icon_color"
                                                    >
                                                        mdi-account
                                                    </v-icon>
                                                </v-text-field>                                                
                                    
                                                <div class="text-right mt-1 mb-4">                                                
                                                    <v-btn :color="icon_color" @click="enterPassword" dark>NEXT</v-btn>
                                                </div>
                                            </v-form>                                            
                                
                                        </v-window-item>

                                        <v-window-item :value="2">

                                            <div class="pa-4">
                                                <v-alert                                                    
                                                    type="error"
                                                    outlined                                                    
                                                    v-if="getloginErrors"
                                                    class="red--text"
                                                >
                                                    {{ getloginErrors }}
                                                </v-alert>    
                                            </div>

                                            <v-card-text>
                                                <div :class="title_css">Enter Password</div>                                            
                                            </v-card-text>                                            

                                            <v-form 
                                                class="pa-5"
                                                @submit.prevent="submit"
                                            >
                                                <v-text-field
                                                    v-show="password"
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    :label="passwordLabel"
                                                    :placeholder="passwordPlaceholder"
                                                    :hint="passwordHint"
                                                    persistent-hint
                                                    @click:append="show = !show"                                                    
                                                    name="password"
                                                    v-model="formData.password"
                                                    clearable
                                                    autofocus
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        :color="icon_color"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                            
                                        
                                                <div class="text-right mt-1  mb-4">                                                
                                                    <v-btn :color="icon_color" @click="submit" dark>LOGIN</v-btn>
                                                </div>
                                            </v-form>

                                        </v-window-item>                                        

                                    </v-window>                                            
                                </v-col>
                                
                                    </v-row>                               
                                
                           
                        </v-card>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="getOverlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
                <!-- <query-id/> -->
            </v-container>            
        </v-main>
        
    </v-app>
</template>

<script>
//import QueryId from '../QueryId'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'AdminLogin',
    components: {
        //QueryId,
    },
    data(){
        return {
            step: 1,
            show: false,
            userpassword: '',
            formData: {
                name: '',
                password: '',                
            },
            password: true,
            studentIdQuery: false,
            passwordPlaceholder: 'Enter password',
            passwordLabel: 'Password',
            passwordHint: '',
            windowTitle: 'Enter Password',
            resetPasswordErrors: '',                      
        }
    },         
    props: {
        source: String,
    },
    watch: {
        userpassword: {
            handler(val){
                this.formData.student_id = val;                
            }
        },              
    },
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'termReports/getSchoolName',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',
            getUser: 'auth/getUser',                   
        }),
        getThemeColor(){          
          return 'primary';
        },
        signInClass(){
            return 'primary d-flex pt-4 flex-column justify-center align-center';
        },
        title_css(){
            return  'primary--text text-h5 text-left ';
        },
        icon_color(){
             return 'primary';
        },
        
    },
    methods: {
        ...mapActions({
            signIn: 'auth/adminSignIn',
            getPermissions: 'auth/adminPermissions',
        }),

        ...mapMutations({
            openDialog: 'auth/setDialog',            
            setUserId: 'auth/setUserId',            
            setUser: 'form/setUser',
            loginError: 'auth/setLoginErrors',
            setAdminPermissions: 'auth/setAdminPermissions', 
            setOverlay: 'auth/setOverlay',           
            //getUser: 'auth/getUser',
        }),
        nextStep(e){
            if(e.keyCode === 13){
                //console.log('Enter was pressed');
                this.loginError('');
                this.step++;
            }
        },       
        async submit () {
            this.setOverlay(true);            
            await this.signIn(this.formData);            
            if(this.getAuthenticated){                              
                this.setUser = this.getUser.name[0];
                try {
                    const { data } = await this.getPermissions();
                    this.setAdminPermissions(JSON.stringify(data));
                    sessionStorage.setItem('adminPermissions', JSON.stringify(data));
                    // console.log(data);
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                }                
                //console.log(this.getUser.name[0]);
                this.setOverlay(false);
                this.$router.replace('/admin/dashboard');
            }
            this.setOverlay(false);
        },

        enterPassword(){
            //console.log(this.formData);
            if(this.formData.name){
                this.loginError('');
                this.step++;
            }
            else{
                this.loginError('Username field empty');
            }
        }        
        
    }
}
</script>

<style scoped>
    .container-background{
        background-image: url('../../assets/background.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    

</style>